<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/takeUpProducerService'
import takeUpProducerGinService from '../api/takeUpProducerGinService'
import takeUpCounterPartyRefService from '../api/takeUpCounterPartyRefService'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getTakeUpProducerGinMeta() {
      const takeUpProducerGinMeta = await takeUpProducerGinService.getMeta()
      helpers.processMetaDetails(
        takeUpProducerGinMeta,
        'takeUpProducerGin',
        'producerId',
        'producer',
        takeUpProducerGinService
      )
      return takeUpProducerGinMeta
    },
    async getTakeUpCounterPartyRefMeta() {
      const takeUpCounterPartyRefMeta =
        await takeUpCounterPartyRefService.getMeta()
      helpers.processMetaDetails(
        takeUpCounterPartyRefMeta,
        'takeUpCounterPartyRef',
        'producerId',
        'producer',
        takeUpCounterPartyRefService
      )
      return takeUpCounterPartyRefMeta
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getTakeUpProducerGinMeta(),
        this.getTakeUpCounterPartyRefMeta()
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMetaWithValidation(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'controlTower.pages')
      this.metadata = meta
    })
  }
}
</script>
