import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/controltower/takeUpProducerGin', api, {
  importLogUrl: '/api/controltower/importLog',
  masterName: 'producerId'
})

service.processValidationContext = (_component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.gin.options = ctx.ginOptions
  fieldsById.producer.options = ctx.producerOptions
}

export default service
